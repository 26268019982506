<script>
import ListMain from '../../../../components/list/Main'
import ListHeader from '../../../../components/list/Header'
import ListEditButton from '../../../../components/list/EditButton'
import ListDeleteButton from '../../../../components/list/DeleteButton'
import ListExport from '../../../../components/list/Export'
import GripoDialog from '../../../../components/common/Dialog'
import ShowPdf from '../../../../components/common/ShowPdf'
import ProductTypeLabel from './TypeLabel'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'
import * as fileService from '@app/services/fileService'

import bus from '@utils/bus'
import {currencyConfig} from "@utils/money";
import {successDialog, warningDialog} from '@utils/flash'
import {mapGetters} from 'vuex'

import {clone} from 'lodash'
import {required} from 'vuelidate/lib/validators'

const defaultFilter = {
  stock: null,
  active: true,
  nfse_enabled: 'all',
  nfce_enabled: 'all',
  type: 'all',
  use_plan_discount: 'all',
}

export default {
  components: {
    ShowPdf,
    ListMain,
    ListHeader,
    ListEditButton,
    ListDeleteButton,
    ListExport,
    GripoDialog,
    ProductTypeLabel
  },
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      updatePrice: false,
      filters: {...defaultFilter},
      dependencies: {
        products: [],
        product_categories: [],
        product_tags: []
      },
      isModalChangeStockVisible: false,
      isModalImportProductsVisible: false,
      updateStock: {
        product_id: null,
        product_description: '',
        current_stock: 0,
        quantity: 1,
        type: 'input',
        notes: ''
      },
      pdfBase64String: '',
      exportFields: [
        {field: 'id', label: 'ID'},
        {field: 'type', label: 'Tipo'},
        {field: 'code', label: 'Código Interno'},
        {field: 'category', label: 'Categoria'},
        {field: 'description', label: 'Descrição'},
        {field: 'created_at', label: 'Data de Criação'},
        {field: 'purchase_price', label: 'Preço de Compra'},
        {field: 'price', label: 'Preço de Venda'},
        {field: 'stock', label: 'Estoque'},
        {field: 'alert_minimum_stock', label: 'Estoque Mínimo'},
        {field: 'tags', label: 'Tags'},
        {field: 'codigo_ncm', label: 'Código NCM'},
        {field: 'cfop', label: 'Código Fiscal da Operação (CFOP)'},
        {field: 'icms_origem', label: 'ICMS Origem'},
        {field: 'icms_situacao_tributaria', label: 'ICMS Situação Tributária (CST)'},
        {field: 'cest', label: 'CEST'},
        {field: 'codigo_cnae', label: 'Código Cnae'},
        {field: 'codigo_tributario_municipio', label: 'Código Tributário do Município'},
        {field: 'item_lista_servico', label: 'Item da Lista de Serviço'},
        {field: 'iss_retido', label: 'ISS Retido'},
        {field: 'aliquota', label: 'Aliquota'},
      ],
      importProducts: {
        reset: false,
        type: '',
        size: '',
        filename: '',
        base64: '',
        generate_entry: false,
        due_date: '',
        showProductList: false,
        products: [],
        amount: 0,
        nfe_number: '',
        supplier_cnpj: '',
        supplier_name: '',
      }
    }
  },
  validations: {
    updateStock: {
      product_id: {required},
      quantity: {required},
      type: {required}
    }
  },
  mounted() {
    this.getDependencies()
    const searchData = this.prepareSearchData()
    bus.$emit('list-init', {domain: 'product', relations: ['category'], data: searchData}, () => {
      bus.$emit('hide-loader')
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore', 'tenant', 'hasCommercialReceiptModule', 'hasCommercialInvoiceModule', 'hasProfessionalPlan']),
    moneyMask() {
      return currencyConfig()
    },
    exportFieldsFiltered() {
      let list = [...this.exportFields]
      if (!this.hasCommercialReceiptModule) {
        list = [...list].filter(item => !['codigo_ncm', 'cfop', 'icms_origem', 'icms_situacao_tributaria', 'cest'].includes(item.field))
      }
      if (!this.hasCommercialInvoiceModule) {
        list = [...list].filter(item => !['codigo_cnae', 'codigo_tributario_municipio', 'item_lista_servico', 'iss_retido', 'aliquota'].includes(item.field))
      }
      if (!this.hasProfessionalPlan) {
        list = [...list].filter(item => !['type', 'stock', 'alert_minimum_stock'].includes(item.field))
      }
      return list
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigCodigoCnaeObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_cnae_obrigatorio_nfse ?? false
    },
    nfseCityConfigItemListaServicoObrigatorioNfse() {
      return this.nfseCityConfig?.item_lista_servico_obrigatorio_nfse ?? true
    },
    nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_tributario_municipio_obrigatorio_nfse ?? false
    }
  },
  methods: {
    getDependencies() {
      DataService.get([
        {domain: 'product', data: {type: 'product'}},
        {domain: 'product_category'},
        {domain: 'product_tags'}
      ]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    prepareSearchData() {
      const searchData = {}
      if (!this.hasProfessionalPlan) {
        this.filters.type = 'service'
      }
      if (this.filters.description) {
        searchData.description = {
          'like': '%' + this.filters.description + '%'
        }
      }
      if (this.filters.code) {
        searchData.code = {
          'like': '%' + this.filters.code + '%'
        }
      }
      if (this.filters.barcode) {
        searchData.barcode = {
          'like': '%' + this.filters.barcode + '%'
        }
      }
      if (this.filters.stock) {
        let sign = this.filters.stock === 0 ? '=' : (this.filters.stock > 0 ? '>' : '<')
        searchData.stock = {}
        searchData.stock[sign] = 0
      }
      if (this.filters.category_id) {
        searchData.category_id = this.filters.category_id
      }
      if (this.filters.type !== 'all') {
        searchData.type = this.filters.type
      }
      if (this.filters.use_plan_discount !== 'all') {
        searchData.use_plan_discount = this.filters.use_plan_discount === 'yes'
      }
      searchData.nfce_enabled = this.filters.nfce_enabled
      searchData.nfse_enabled = this.filters.nfse_enabled
      searchData.active = this.filters.active
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      bus.$emit('list-filter', data)
    },
    onFilterClean() {
      this.filters = {...defaultFilter}
      const data = this.prepareSearchData()
      bus.$emit('list-filter-clean', data)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    toggleChangeStock() {
      this.updateStock.quantity = 0
      this.updateStock.type = 'input'
      this.updateStock.notes = ''
      this.isModalChangeStockVisible = !this.isModalChangeStockVisible
    },
    changeStock(item) {
      this.updateStock.product_id = item.id
      this.updateStock.product_description = item.description
      this.updateStock.current_stock = item.stock
      this.toggleChangeStock()
    },
    onSaveUpdateStock() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = clone(this.updateStock)
      restfulService.put('product', 'update-stock', data.product_id, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.onFilter()
            this.toggleChangeStock()
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    onExport({format, fields}) {
      const filters = {...this.prepareSearchData()}
      if ('pdf' === format) {
        this.exportPdf(filters, fields)
      } else if ('csv' === format) {
        this.exportCsv(filters, fields)
      }
    },
    exportPdf(filters, fields) {
      bus.$emit('show-loader')
      const searchData = {filters, fields, format: 'pdf'}
      restfulService.post('report/product', 'export-search', null, searchData)
          .then(response => {
            this.pdfBase64String = response.file
            bus.$emit('hide-loader')
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
    exportCsv(filters, fields) {
      bus.$emit('show-loader')
      const data = {filters, fields, format: 'csv'}
      fileService.get('report/product', 'export-search', null, 'csv', data, false, 'Relatório de Produtos e Serviços')
          .finally(() => {
            bus.$emit('hide-loader')
          })
    },
    toggleImportProducts() {
      this.resetFile()
      this.importProducts.due_date = ''
      this.importProducts.generate_entry = false
      this.importProducts.amount = 0
      this.importProducts.nfe_number = ''
      this.importProducts.supplier_name = ''
      this.importProducts.supplier_cnpj = ''
      this.isModalImportProductsVisible = !this.isModalImportProductsVisible
    },
    resetFile() {
      this.importProducts.reset = true
      this.importProducts.filename = ''
      this.importProducts.base64 = ''
      this.importProducts.size = ''
      this.importProducts.type = ''
      this.importProducts.products.splice(0)
      this.importProducts.showProductList = false
      setTimeout(() => {
        this.importProducts.reset = false
      }, 50)
    },
    selectFile(event) {
      const file = event.target.files[0]
      if (file.type !== 'text/xml') {
        warningDialog({
          title: 'Formato de arquivo inválido',
          text: 'Selecione um arquivo com extensão .xml',
        })
        event.target.value = null
        return
      }
      this.importProducts.filename = file.name
      this.importProducts.size = file.size
      this.importProducts.type = file.type
      let reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.importProducts.base64 = reader.result
        this.onImportProducts(false)
      }
    },
    onImportProducts(forced) {
      forced = forced || false
      bus.$emit('show-loader')
      this.isSubmitted = true
      if (!forced) {
        bus.$emit('show-loader', 'Carregando produtos...')
      } else {
        bus.$emit('show-loader')
      }
      const data = {
        ...clone(this.importProducts),
        forced,
        products: forced
            ? this.importProducts.products.filter(item => item.checked)
            : []
      }
      if (data.forced) {
        delete data.reset
        delete data.filename
        delete data.base64
        delete data.type
        delete data.size
      }
      restfulService.post('product', 'import', null, data)
          .then(response => {
            if (forced) {
              successDialog({
                title: 'Produtos importados com sucesso!',
                html: `Total de Produtos da NFe: ${response.uploaded}<br>Total de Produtos criados: ${response.created}<br>Total de Produtos atualizados: ${response.updated}`,
              })
              this.isSubmitted = false
              this.toggleImportProducts()
              this.onFilter()
            } else {
              this.importProducts.due_date = response.due_date
              this.importProducts.amount = response.amount
              this.importProducts.supplier_name = response.supplier_name
              this.importProducts.supplier_cnpj = response.supplier_cnpj
              this.importProducts.nfe_number = response.nfe_number
              this.importProducts.products = [...response.products]
              this.importProducts.showProductList = true
              this.isSubmitted = false
              setTimeout(() => {
                this.updatePrice = false
                bus.$emit('hide-loader')
              }, 50)
            }
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <show-pdf :base64="pdfBase64String"/>

    <ListHeader :title="hasProfessionalPlan ? 'Produtos/Serviços' : 'Serviços'" route-name="product.store">
      <el-button type="warning" @click="toggleImportProducts" v-if="hasProfessionalPlan">
        <i class="fas fa-file-code"></i>
        <span>Importar Nota de Compra</span>
      </el-button>
    </ListHeader>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtros</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Aplicar Filtros</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-times"></i>
                <span>Limpar</span>
              </el-button>
              <list-export @export="onExport"
                           :fields="exportFieldsFiltered"
                           :base64-pdf="pdfBase64String"/>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Tipo" v-if="hasProfessionalPlan">
              <el-select v-model="filters.type"
                         clearable
                         placeholder="Filtrar por Tipo">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Produto" value="product"/>
                <el-option label="Serviço" value="service"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Descrição">
              <el-input v-model="filters.description"
                        placeholder="Filtrar por descrição"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Código Interno">
              <el-input v-model="filters.code"
                        placeholder="Filtrar por código interno"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Categoria">
              <el-select v-model="filters.category_id"
                         filterable
                         clearable
                         placeholder="Selecione uma categoria"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full"
                         @keydown.enter.native.prevent="onFilter">
                <el-option
                    v-for="item in dependencies.product_categories"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Estoque" v-if="hasProfessionalPlan">
              <el-select v-model="filters.stock"
                         clearable
                         placeholder="Filtrar por Estoque"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Positivo" :value="1"/>
                <el-option label="Negativo" :value="-1"/>
                <el-option label="Zerado" :value="0"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="hasCommercialReceiptModule" label="Habilitado para NFCe">
              <el-select v-model="filters.nfce_enabled"
                         clearable
                         placeholder="Filtrar por NFCe"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Habilitado" value="yes"/>
                <el-option label="Não Habilitado" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="hasCommercialInvoiceModule" label="Habilitado para NFSe">
              <el-select v-model="filters.nfse_enabled"
                         clearable
                         placeholder="Filtrar por NFSe"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Habilitado" value="yes"/>
                <el-option label="Não Habilitado" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Desconto de Planos e Pacotes" v-if="hasProfessionalPlan">
              <el-select v-model="filters.use_plan_discount"
                         placeholder="Filtrar por Aplicar Desconto"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="yes"/>
                <el-option label="Não" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Ativo">
              <el-switch v-model="filters.active"
                         active-color="#13ce66"
                         :active-value="true"
                         active-text="SIM"
                         inactive-color="#ff4949"
                         :inactive-value="false"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>Tipo</th>
            <th>Código Interno</th>
            <th>Categoria</th>
            <th>Descrição</th>
            <th>Preço de Compra</th>
            <th>Preço de Venda</th>
            <th v-if="hasProfessionalPlan">Estoque</th>
            <th v-if="hasProfessionalPlan">Estoque Mínimo</th>
            <th class="text-center" v-if="hasCommercialReceiptModule || hasCommercialInvoiceModule">Habilitado p/ NF
            </th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in listStore.listData" :key="index">
            <td>
              <product-type-label :type="item.type"/>
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.category ? item.category.name : '' }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.purchase_price | currencyFormatter }}</td>
            <td>{{ item.price | currencyFormatter }}</td>
            <td v-if="hasProfessionalPlan">{{ item.control_stock ? item.stock : '-' }}</td>
            <td v-if="hasProfessionalPlan">{{ item.control_stock ? item.alert_minimum_stock : '-' }}</td>
            <td class="text-center">
              <el-tag size="mini" type="success"
                      v-if="(hasCommercialReceiptModule && item.type === 'product' || hasCommercialReceiptModule && item.type === 'service') && item.nf_enabled">
                Habilitado
              </el-tag>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-button type="warning"
                           v-if="item.control_stock"
                           @click="changeStock(item)"
                           title="Alterar Estoque">
                  <i class="fas fa-layer-group"></i>
                </el-button>
                <ListEditButton route-name="product.update" :item="item"/>
                <ListDeleteButton :item="item" domain="product"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>

    <gripo-dialog :model.sync="isModalChangeStockVisible"
                  width="60vw">
      <template v-slot:header>Alterar Estoque - Produto: {{ updateStock.product_description }} (Estoque Atual:
        {{ updateStock.current_stock }})
      </template>
      <template v-slot:content>
        <el-form ref="form"
                 :model="updateStock"
                 label-position="top">
          <el-form-item label="Tipo"
                        class="is-required">
            <el-radio v-model="updateStock.type" label="input" border>Entrada</el-radio>
            <el-radio v-model="updateStock.type" label="output" border>Saída</el-radio>
          </el-form-item>
          <el-form-item label="Estoque"
                        class="is-required">
            <el-input-number v-model="updateStock.quantity"
                             :min="0"
                             :step="1"/>
          </el-form-item>
          <el-form-item label="Observações">
            <el-input type="textarea"
                      :rows="3"
                      v-model="updateStock.notes"/>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="onSaveUpdateStock"
                   :disabled="isSubmitted || $v.updateStock.$invalid">Salvar Alterações
        </el-button>
      </template>
    </gripo-dialog>

    <gripo-dialog :model.sync="isModalImportProductsVisible"
                  title="Importar Produtos - Selecione a NFe de Compra"
                  width="80vw">
      <template v-slot:content>
        <el-form ref="form"
                 :model="importProducts"
                 label-position="top">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="Selecione XML da Nota de Compra"
                            class="is-required">
                <input v-if="!importProducts.reset"
                       type="file"
                       accept="text/xml"
                       @change="selectFile($event)">
                <el-button @click="resetFile"
                           size="mini"
                           class="mt-2"
                           v-if="!!importProducts.base64">
                  <i class="fas fa-times"></i>
                  <span>Remover Arquivo</span>
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Gerar Lançamento no Contas a Pagar">
                <el-switch v-model="importProducts.generate_entry"
                           active-color="#13ce66"
                           :active-value="true"
                           active-text="SIM"
                           :inactive-value="false"
                           inactive-text="NÃO">
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="importProducts.generate_entry">
              <el-form-item label="Vencimento">
                <el-date-picker
                    v-model="importProducts.due_date"
                    type="date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    v-mask="'##/##/####'"
                    placeholder="Vencimento">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="importProducts.showProductList">
            <el-divider class="mt-1 mb-3"/>
            <h2 class="mb-1">Produtos a serem Importados</h2>
            <p class="mt-0 mb-2">Desmarque os produtos para não serem importados</p>
            <template v-for="(product, index) in importProducts.products">
              <el-row :gutter="10" :key="index * 2">
                <el-col :span="7">
                  <div class="flex-c gap-10">
                    <el-checkbox v-model="product.checked"/>
                    <el-form-item label="Vincular ao Produto"
                                  class="el-select-full">
                      <el-select v-model="product.id"
                                 filterable
                                 clearable
                                 placeholder="Criar produto novo"
                                 empty-text="Nenhum registro encontrado"
                                 class="el-select-full">
                        <el-option
                            v-for="item in dependencies.products"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id">
                        </el-option>
                      </el-select>
                      <div class="mt-1">
                        <strong>Descrição na nota:</strong> {{ product.description }}
                      </div>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="Categoria"
                                class="el-select-full">
                    <el-select v-model="product.category_id"
                               filterable
                               clearable
                               placeholder="Selecione a categoria"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full">
                      <el-option
                          v-for="item in dependencies.product_categories"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Quantidade">
                    <el-input-number v-model="product.quantity"
                                     :precision="product.unit === 'UN' ? 0 : 3"
                                     :step="product.unit === 'UN' ? 1 : 0.001"
                                     :min="0.001"/>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Preço de Compra">
                    <el-input v-model.lazy="product.purchase_price"
                              v-money="moneyMask"
                              v-if="!updatePrice"/>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Preço de Venda">
                    <el-input v-model.lazy="product.price"
                              v-money="moneyMask"
                              v-if="!updatePrice"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider class="mt-0 mb-2" :key="index * 20"/>
            </template>
          </div>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <el-button type="primary"
                   @click="onImportProducts(true)"
                   :disabled="isSubmitted || !importProducts.base64">Confirmar Importação
        </el-button>
      </template>
    </gripo-dialog>
  </div>
</template>
