<script>
import GripoDialog from '@components/common/Dialog'
import FormSaveButton from '@components/form/SaveButton'
import TenantConfigCertificate from './Certificate.vue'

import * as restfulService from '@app/services/restfulService'
import * as notifyService from '@app/services/notifyService'
import DataService from '@app/services/dataService'

import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {required} from 'vuelidate/lib/validators'
import {cloneDeep} from 'lodash'
import {defaultConfigData} from '@app/domains/system/tenant/service'
import {confirmDialog, warningDialog} from '@utils/flash'
import {transactionFeePercentage, withdrawAmount} from '@utils/pagarMe'

const defaultPaymentAccount = {
  id: '',
  cpf_cnpj: '',
  bank_code: '',
  agency: '',
  account: '',
  digit: '',
  main: false,
  is_edit: false,
}

export default {
  components: {TenantConfigCertificate, GripoDialog, FormSaveButton},
  name: 'tenant-config',
  props: ['form'],
  data() {
    return {
      isFirstLoad: true,
      isSubmitted: false,
      showPassword: false,
      nfeTab: 'nfse',
      localForm: {...cloneDeep(defaultConfigData)},
      dependencies: {
        nf_regime_tributario: [],
        nf_regime_especial_tributacao: [],
        banks: [],
        tenant_payment_accounts: [],
      },
      paymentAccounts: [],
      paymentAccount: {
        data: {...defaultPaymentAccount},
        showDialog: false
      },
      stoneConnectDevice: {
        showForm: false,
        data: {
          name: '',
          sn: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters(['tenant', 'hasStoneConnectModule', 'hasCommercialReceiptModule']),
    wsKeyTenantPaymentAccountUpdateChannel() {
      const event = 'tenantPaymentAccountUpdate'
      return {name: `${event}.${this.tenant.id}`, listen: `.${event}`}
    },
    isCountryBrazil() {
      return (this.tenant?.country_id || 1 === 1)
    },
    canUseGatewayPayments() {
      return this.isCountryBrazil
    },
    canUseStoneConnect() {
      return this.isCountryBrazil
    },
    canUseNf() {
      return this.isCountryBrazil
    },
    pagarMeTransactionFeePercentage() {
      return parseFloat(transactionFeePercentage || 0)
    },
    pagarMeWithdrawAmount() {
      return parseFloat(withdrawAmount || 0)
    },
    hasBasicPlan() {
      return (this.tenant?.modules?.basic ?? false)
    },
    hasProfessionalPlan() {
      return (this.tenant?.modules?.professional ?? false)
    },
    hasPaymentAccount() {
      return (this.tenant?.has_payment_account ?? false)
    },
    hasCertificate() {
      return this.form.tenant_nf.certificate
          && (this.form.tenant_nf.certificate.status === 'active' || this.form.tenant_nf.certificate.expired === true)
    },
    hasExpiredCertificate() {
      return this.form.tenant_nf.certificate
          && (this.form.tenant_nf.certificate.status === 'expired' || this.form.tenant_nf.certificate.expired === true)
    },
    activePaymentAccounts() {
      return this.dependencies.tenant_payment_accounts.filter(item => item.status === 'active')
    },
    stoneConnectDevices() {
      return this.form.tenant_stone_payment?.devices || []
    },
    tenantNfseEnabled() {
      return this.form.tenant_nf?.nfse?.enabled || false
    },
    tenantNfceEnabled() {
      return this.form.tenant_nf?.nfce?.enabled || false
    },
    canEnableNfse() {
      return this.form.tenant_nf?.nfse_city_enabled
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigCodigoCnaeObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_cnae_obrigatorio_nfse ?? false
    },
    nfseCityConfigItemListaServicoObrigatorioNfse() {
      return this.nfseCityConfig?.item_lista_servico_obrigatorio_nfse ?? true
    },
    nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse() {
      return this.nfseCityConfig?.codigo_tributario_municipio_obrigatorio_nfse ?? false
    }
  },
  validations: {
    localForm: {
      name: {required},
      tenant_nf: {
        nfce: {
          inscricao_estadual: {required},
          regime_tributario: {required},
          token_csc: {required},
          proximo_numero: {required}
        },
        nfse: {
          inscricao_municipal: {required},
          regime_especial_tributacao: {required},
          proximo_numero: {required}
        }
      }
    },
    paymentAccount: {
      data: {
        cpf_cnpj: {required},
        bank_code: {required},
        agency: {required},
        account: {required},
        digit: {required},
      }
    },
    stoneConnectDevice: {
      data: {
        name: {required},
        sn: {required},
      }
    }
  },
  watch: {
    localForm: {
      deep: true,
      handler: function (value) {
        this.$emit('update:form', value)
      }
    },
    'localForm.tenant_stone_payment.enabled': function () {
      if (!this.isFirstLoad) {
        this.onSaveSettings('tenant_stone_payment')
      }
    },
    'localForm.tenant_stone_payment.auto_generate_invoice': function () {
      if (!this.isFirstLoad) {
        this.onSaveSettings('tenant_stone_payment')
      }
    }
  },
  async mounted() {
    bus.$emit('show-loader')
    this.startWebsocket()
    this.getDependencies()
    setTimeout(() => {
      this.localForm = {...this.localForm, ...cloneDeep(this.form)}
      setTimeout(() => {
        this.nfeTab = this.tenantNfceEnabled ? 'nfce' : 'nfse'
        this.isFirstLoad = false
        bus.$emit('hide-loader')
      }, 300)
    }, 100)
  },
  methods: {
    startWebsocket() {
      window.Echo.channel(this.wsKeyTenantPaymentAccountUpdateChannel.name)
          .listen(this.wsKeyTenantPaymentAccountUpdateChannel.listen, () => {
            this.reloadPaymentAccounts()
          })
    },
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([
          {domain: 'nf_regime_tributario'},
          {domain: 'nf_regime_especial_tributacao'},
          {domain: 'tenant_payment_account'},
          {domain: 'bank'},
        ]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
        })
        resolve()
      })
    },
    reloadPaymentAccounts() {
      DataService.get([
        {domain: 'tenant_payment_account'},
      ]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    onSend() {
      this.$emit('onSend')
    },
    resetPaymentAccount(paymentAccount) {
      this.paymentAccount.data = {...defaultPaymentAccount}
      this.paymentAccount.is_edit = false
      if (paymentAccount) {
        this.paymentAccount.is_edit = true
        this.paymentAccount.data = {...this.paymentAccount.data, ...paymentAccount}
      }
    },
    handlePaymentAccount(paymentAccount) {
      if (!this.paymentAccount.showDialog) {
        this.resetPaymentAccount(paymentAccount)
      }
      this.paymentAccount.showDialog = !this.paymentAccount.showDialog
    },
    async savePaymentAccount() {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente gravar essa conta?',
        text: 'Confira os dados para garantir que está tudo correto!',
        icon: 'warning'
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        restfulService.post('tenant', 'save-payment-account', null, {...this.paymentAccount.data})
            .then((response) => {
              notifyService.success()
              this.dependencies.tenant_payment_accounts = this.dependencies.tenant_payment_accounts.filter(item => item.id !== response.id)
              if (response.main && this.dependencies.tenant_payment_accounts.length) {
                this.dependencies.tenant_payment_accounts = this.dependencies.tenant_payment_accounts.map(item => {
                  item.main = false
                  return item
                })
              }
              this.dependencies.tenant_payment_accounts.push(response)
              this.handlePaymentAccount(null)
              bus.$emit('reload-session-info')
              bus.$emit('hide-loader')
              this.isSubmitted = false
            })
            .catch(e => {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
            })
      }
    },
    async deletePaymentAccount(paymentAccount) {
      const {value: confirm} = await confirmDialog({
        title: 'Deseja realmente desativar essa conta?',
      })
      if (confirm) {
        bus.$emit('show-loader')
        this.isSubmitted = true
        restfulService.post('tenant', 'delete-payment-account', null, {id: paymentAccount.id})
            .then(() => {
              notifyService.success()
              this.dependencies.tenant_payment_accounts = this.dependencies.tenant_payment_accounts.filter(item => item.id !== paymentAccount.id)
              bus.$emit('hide-loader')
              this.isSubmitted = false
            })
            .catch(e => {
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
            })
      }
    },
    onSaveSettings(type) {
      return new Promise((resolve, reject) => {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = {
          items: [
            {
              type: type,
              data: {...this.localForm[type]}
            }
          ]
        }
        restfulService.post('tenant', 'settings', null, data)
            .then((tenant) => {
              notifyService.success()
              bus.$emit('reload-session-info', async () => {
                this.$emit('reloadData', tenant)
                setTimeout(() => {
                  this.isSubmitted = false
                  bus.$emit('hide-loader')
                }, 300)
                resolve()
              })
            })
            .catch(e => {
              this.localForm.tenant_stone_payment.requested = false
              bus.$emit('hide-loader')
              this.isSubmitted = false
              console.log(e)
              reject(e)
            })
      })
    },
    onSaveNfceConfig() {
      this.onSaveNfConfig({type: 'nfce', ...this.localForm.tenant_nf.nfce, nfce_staging: {...this.localForm.tenant_nf.nfce_staging}})
    },
    onSaveNfseConfig() {
      this.onSaveNfConfig({type: 'nfse', ...this.localForm.tenant_nf.nfse})
    },
    onSaveNfConfig(data) {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.post('tenant', 'save-nf-settings', null, data)
          .then(() => {
            bus.$emit('reload-session-info', () => {
              notifyService.success()
              bus.$emit('hide-loader')
              this.isSubmitted = false
            })
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    },
    handleStoneDevice(add) {
      if (!this.localForm.tenant_stone_payment.active || !this.localForm.tenant_stone_payment.enabled) {
        return
      }
      add = add || false
      if (add) {
        this.stoneConnectDevice.data.name = ''
        this.stoneConnectDevice.data.sn = ''
      }
      this.stoneConnectDevice.showForm = add
    },
    async onAddStoneDevice() {
      const {name, sn} = this.stoneConnectDevice.data
      if (this.localForm.tenant_stone_payment.devices.some(item => item.sn === sn)) {
        warningDialog({
          title: 'Já existe uma máquina cadastrada com esse Número de Série',
        })
      } else {
        this.localForm.tenant_stone_payment.devices.push({name, sn})
        await this.onSaveSettings('tenant_stone_payment')
        this.handleStoneDevice()
      }
    },
    async deleteStoneDevice(index) {
      this.localForm.tenant_stone_payment.devices.splice(index, 1)
      await this.onSaveSettings('tenant_stone_payment')
      this.handleStoneDevice()
    },
    async requestStoneConnect() {
      this.localForm.tenant_stone_payment.requested = true
      await this.onSaveSettings('tenant_stone_payment')
    }
  }
}
</script>

<template>
  <el-form ref="form"
           :model="localForm"
           label-position="top"
           @submit.prevent="onSend">
    <v-container grid-list-lg class="m-0 p-0" v-if="localForm.id">
      <v-layout row wrap>
        <v-flex xs12 sm6>
          <el-card class="el-card__save_form box-card" v-if="canUseGatewayPayments">
            <div slot="header">
              <div>
                <span class="mr-1">Pagamento Online</span>
                <el-tag type="success" size="mini" effect="dark" v-if="activePaymentAccounts.length">Ativo</el-tag>
                <el-tag type="danger" size="mini" v-else>Inativo</el-tag>
              </div>
              <el-button size="mini" type="warning" class="mr-1" @click="handlePaymentAccount(null)">
                <i class="fas fa-plus"></i>
                <span class="ml-1">Inserir Nova Conta</span>
              </el-button>
            </div>
            <el-alert type="info"
                      show-icon
                      :closable="false"
                      class="mb-2"
                      v-if="!activePaymentAccounts.length">
              <div slot="title">
                Insira uma conta bancária para ativar o pagamento online para reservas e torneios
              </div>
            </el-alert>
            <el-alert type="warning"
                      show-icon
                      :closable="false">
              <div slot="title">
                <strong>Taxas do Pagamento Online</strong>
              </div>
              <div class="fs-16">
                <strong>{{ pagarMeTransactionFeePercentage }}%</strong> por pagamento realizado e
                <strong>{{ pagarMeWithdrawAmount | currencyFormatter }}</strong> por saque
              </div>
            </el-alert>
            <div class="table-responsive mt-3" v-if="activePaymentAccounts.length">
              <table class="custom-table-list">
                <thead>
                <tr>
                  <th width="30%">Banco</th>
                  <th>Dados</th>
                  <th class="btn-actions">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="clientPaymentAccount in activePaymentAccounts" :key="clientPaymentAccount.id">
                  <td>
                    <div v-if="clientPaymentAccount.bank">
                      ({{ clientPaymentAccount.bank_code }}) {{ clientPaymentAccount.bank }}
                    </div>
                    <div class="mt-1" v-if="clientPaymentAccount.main">
                      <el-tag type="success" size="mini" effect="dark">
                        Conta Principal
                      </el-tag>
                    </div>
                  </td>
                  <td>
                    <div>
                      <strong>Agência: </strong> {{ clientPaymentAccount.agency }}
                      <strong>Conta: </strong> {{ clientPaymentAccount.account }}-{{ clientPaymentAccount.digit }}
                    </div>
                    <div>
                      <strong>CNPJ/CPF: </strong> {{ clientPaymentAccount.cpf_cnpj }}
                    </div>
                  </td>
                  <td>
                    <div class="btn-actions">
                      <el-button type="info"
                                 size="mini"
                                 title="Edit conta de pagamento"
                                 @click="handlePaymentAccount(clientPaymentAccount)">
                        <i class="fas fa-pencil-alt"></i>
                      </el-button>
                      <el-button type="danger"
                                 size="mini"
                                 title="Desativar conta de pagamento"
                                 @click="deletePaymentAccount(clientPaymentAccount)">
                        <i class="fas fa-trash"></i>
                      </el-button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </el-card>
          <el-card :class="['el-card__save_form box-card mt-3']" v-if="canUseStoneConnect && hasStoneConnectModule">
            <div slot="header">
              <div>
                <span>TEF Stone - POS Smart Connect</span>
                <el-tag type="success" class="ml-1" size="mini" effect="dark"
                        v-if="localForm.tenant_stone_payment.active && localForm.tenant_stone_payment.enabled">Ativo
                </el-tag>
              </div>
              <div v-if="!hasProfessionalPlan">
                <el-alert type="error"
                          show-icon
                          :closable="false"
                          class="ml-1"
                          center>
                  <div slot="title" class="text-center">
                    Disponível no plano de gestão profissional
                  </div>
                </el-alert>
              </div>
            </div>
            <div :class="{'disabled': !hasProfessionalPlan}">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-form-item label="Código Stone" size="small">
                    <el-input v-model="localForm.tenant_stone_payment.stoneCode"
                              :readonly="(localForm.tenant_stone_payment.active && localForm.tenant_stone_payment.requested) || localForm.tenant_stone_payment.enabled"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item class="el-button-form-align">
                    <el-button type="success"
                               size="small"
                               v-if="!localForm.tenant_stone_payment.active && !localForm.tenant_stone_payment.requested"
                               :disabled="isSubmitted || !localForm.tenant_stone_payment.stoneCode"
                               @click="requestStoneConnect">
                      <i class="fas fa-check"></i>
                      <span class="ml-1">Solicitar Integração</span>
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-alert type="warning"
                        show-icon
                        :closable="false"
                        center
                        v-if="localForm.tenant_stone_payment.requested && !localForm.tenant_stone_payment.enabled">
                <div slot="title" class="text-center">
                  <div>A integração foi solicitada e nossa equipe já estará iniciando o processo de liberação.</div>
                  <div>Quando o processo foi concluído você será notificado para iniciar o cadastro das máquinas POS!
                  </div>
                </div>
              </el-alert>
              <el-row :gutter="20" v-if="localForm.tenant_stone_payment.active">
                <el-col :span="12">
                  <el-form-item label="Habilitar Pagamento via Máquina POS">
                    <el-switch v-model="localForm.tenant_stone_payment.enabled"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-if="hasCommercialReceiptModule">
                  <el-form-item label="Impressão de NFCe na Máquina">
                    <el-switch v-model="localForm.tenant_stone_payment.auto_generate_invoice"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <template v-if="localForm.tenant_stone_payment.enabled">
                <template v-if="stoneConnectDevice.showForm">
                  <h3 class="mb-1">Nova Máquina</h3>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Nome">
                        <el-input v-model="stoneConnectDevice.data.name"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Número de Série">
                        <el-input v-model="stoneConnectDevice.data.sn"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-button type="success"
                             :disabled="isSubmitted || $v.stoneConnectDevice.data.$invalid"
                             @click="onAddStoneDevice">
                    <i class="fas fa-check"></i>
                    <span class="ml-1">Salvar Dados</span>
                  </el-button>
                  <el-button type="default"
                             @click="handleStoneDevice(false)">
                    <i class="fas fa-times"></i>
                    <span class="ml-1">Cancelar</span>
                  </el-button>
                </template>
                <div class="mt-3">
                  <div class="flex-c mb-1">
                    <h3 class="mb-1">Máquinas Habilitadas</h3>
                    <el-button size="mini"
                               type="warning"
                               :disabled="!localForm.tenant_stone_payment.enabled"
                               @click="handleStoneDevice(true)">
                      <i class="fas fa-plus"></i>
                      <span class="ml-1">Inserir Nova Máquina</span>
                    </el-button>
                  </div>
                  <div class="table-responsive" v-if="stoneConnectDevices.length">
                    <table class="custom-table-list">
                      <thead>
                      <tr>
                        <th width="30%">Nome</th>
                        <th>Número de Série</th>
                        <th class="btn-actions">Ações</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(device, index) in stoneConnectDevices" :key="index">
                        <td>
                          {{ device.name }}
                        </td>
                        <td>
                          {{ device.sn }}
                        </td>
                        <td>
                          <div class="btn-actions">
                            <el-button type="danger"
                                       size="mini"
                                       title="Remover Máquina"
                                       @click="deleteStoneDevice(index)">
                              <i class="fas fa-trash"></i>
                            </el-button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </div>
          </el-card>
          <el-card :class="['el-card__save_form box-card mt-3']">
            <div slot="header">
              <span>Financeiro</span>
              <div v-if="!hasProfessionalPlan">
                <el-alert type="error"
                          show-icon
                          :closable="false"
                          class="ml-1"
                          center>
                  <div slot="title" class="text-center">
                    Disponível no plano de gestão profissional
                  </div>
                </el-alert>
              </div>
            </div>
            <div :class="{'disabled': !hasProfessionalPlan}">
              <template>
                <el-form-item label="Abertura e Fechamento de Caixa">
                  <el-switch v-model="localForm.tenant_payment.teller_control_enabled"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
                <el-divider class="mt-2 mb-3"/>
              </template>
              <el-row :gutter="20">
                <el-col :span="24">
                  <h4>Enviar Mensagem para o Whatsapp dos Clientes</h4>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Para comandas NÃO PAGAS">
                    <el-switch v-model="localForm.tenant_payment.order_whatsapp_message.unpaid"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Para comandas PAGAS">
                    <el-switch v-model="localForm.tenant_payment.order_whatsapp_message.paid"
                               :active-value="true"
                               :inactive-value="false"
                               active-text="SIM"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="Mensagem Customizada para o Whatsapp">
                <el-input type="textarea"
                          :rows="3"
                          v-model="localForm.tenant_payment.entry_portion_notify_custom_message"/>
              </el-form-item>
              <div>
                <el-alert type="warning"
                          class="pt-3 pb-3 mb-3"
                          show-icon
                          :closable="false"
                          v-if="!hasPaymentAccount">
                  <div slot="title" class="fs-16">
                    Para habilitar o pagamento online cadastre uma conta de pagamentos
                  </div>
                </el-alert>
                <div v-else class="highlight-card -payment mb-3">
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="Habilitar Pagamento Online de Lançamentos pelo App">
                        <el-switch v-model="localForm.tenant_payment.order_online_payment.enabled"
                                   :active-value="true"
                                   :inactive-value="false"
                                   active-text="SIM"
                                   inactive-text="NÃO">
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <form-save-button :event-click="() => onSaveSettings('tenant_payment')"
                                :is-disabled="isSubmitted"
                                :is-submitted="isSubmitted"/>
            </div>
          </el-card>
        </v-flex>
        <v-flex xs12 sm6>
          <el-card class="el-card__save_form box-card el-card__content-no-padding" v-if="canUseNf">
            <div slot="header">
              <span>Notas Fiscais</span>
              <div v-if="!hasBasicPlan && !hasProfessionalPlan">
                <el-alert type="error"
                          show-icon
                          :closable="false"
                          class="ml-1"
                          center>
                  <div slot="title">
                    Disponível no plano de gestão básico ou profissional
                  </div>
                </el-alert>
              </div>
            </div>
            <div :class="{'disabled': !hasBasicPlan && !hasProfessionalPlan}">
              <tenant-config-certificate :certificate.sync="form.tenant_nf.certificate"
                                         @reloadData="$emit('reloadData')"/>
              <el-tabs v-model="nfeTab" stretch :class="{'disabled': hasExpiredCertificate}">
                <el-tab-pane name="nfse" :disabled="!hasBasicPlan && !hasProfessionalPlan">
                  <div slot="label">
                    <span class="mr-1">Configurar NFSe</span>
                    <el-tag type="info" size="mini" v-if="!hasBasicPlan && !hasProfessionalPlan">Indisponível</el-tag>
                    <el-tag type="success" size="mini" effect="dark"
                            v-else-if="canEnableNfse && localForm.tenant_nf.nfse.status === 'active' && localForm.tenant_nf.nfse.enabled">
                      Ativa
                    </el-tag>
                    <el-tag type="danger" size="mini" v-else>Inativa</el-tag>
                  </div>
                  <div class="pt-3 pr-3 pb-3 pl-3">
                    <template v-if="!canEnableNfse">
                      <el-alert type="warning"
                                show-icon
                                :closable="false">
                        <div slot="title" class="fs-16">
                          Seu município não está habilitado para emissão de NFSe, entre em contato
                          com nosso suporte para solicitar a habilitação
                        </div>
                      </el-alert>
                    </template>
                    <template
                        v-if="(!localForm.tenant_nf.nfse.status || localForm.tenant_nf.nfse.status === 'inactive') && !hasCertificate">
                      <el-alert type="warning"
                                show-icon
                                :closable="false">
                        <div slot="title" class="fs-16">
                          Para habilitar a emissão de <strong>NFSe</strong> você precisa primeiro enviar o certificado
                          digital!
                        </div>
                      </el-alert>
                    </template>
                    <template v-if="hasCertificate && canEnableNfse">
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Habilitar NFSe">
                            <el-switch v-model="localForm.tenant_nf.nfse.enabled"
                                       :active-value="true"
                                       :inactive-value="false"
                                       active-text="SIM"
                                       inactive-text="NÃO">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12" v-if="false">
                          <el-form-item label="Ambiente de Homologação/Testes">
                            <el-switch v-model="localForm.tenant_nf.nfse.staging"
                                       :active-value="true"
                                       :inactive-value="false"
                                       active-text="SIM"
                                       inactive-text="NÃO">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Regime Especial Tributação"
                                        class="is-required">
                            <el-select v-model="localForm.tenant_nf.nfse.regime_especial_tributacao"
                                       clearable
                                       filterable
                                       placeholder="Selecione um Regime Tributário"
                                       empty-text="Nenhum registro encontrado"
                                       class="el-select-full">
                              <el-option v-for="item in dependencies.nf_regime_especial_tributacao"
                                         :key="item.id"
                                         :label="`(${item.id}) ${item.name}`"
                                         :value="item.id">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Inscrição Municipal"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfse.inscricao_municipal"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Série do RPS"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfse.serie_nfse"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Próximo Número para Emissão (RPS)"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfse.proximo_numero"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Login (Opcional)">
                            <el-input v-model="localForm.tenant_nf.nfse.login"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Senha (Opcional)">
                            <el-input v-model="localForm.tenant_nf.nfse.password"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-divider class="mt-2 mb-3"/>
                      <h4>Dados de Referência ao Criar Serviços</h4>
                      <el-row :gutter="20" class="pr-2 pl-2">
                        <el-row :gutter="20">
                          <el-col :span="8" v-if="nfseCityConfigCodigoCnaeObrigatorioNfse">
                            <el-form-item label="Código Cnae">
                              <el-input v-model="localForm.tenant_nf.nfse.codigo_cnae"/>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8" v-if="nfseCityConfigItemListaServicoObrigatorioNfse">
                            <el-form-item label="Item da Lista de Serviço">
                              <el-input v-model="localForm.tenant_nf.nfse.item_lista_servico"/>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8" v-if="nfseCityConfigCodigoTributarioMunicipioObrigatorioNfse">
                            <el-form-item label="Código Tributário do Município">
                              <el-input v-model="localForm.tenant_nf.nfse.codigo_tributario_municipio"/>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="ISS Retido">
                              <el-switch v-model="localForm.tenant_nf.nfse.iss_retido"
                                         active-color="#13ce66"
                                         :active-value="true"
                                         active-text="SIM"
                                         inactive-color="#ff4949"
                                         :inactive-value="false"
                                         inactive-text="NÃO">
                              </el-switch>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="Aliquota (%)">
                              <el-input v-model="localForm.tenant_nf.nfse.aliquota"/>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-row>
                      <form-save-button :event-click="onSaveNfseConfig"
                                        text="Salvar Configurações de NFSe"
                                        submitting-text="Salvando Configurações de NFSe"
                                        :is-disabled="isSubmitted || $v.localForm.tenant_nf.nfse.$invalid"
                                        :is-submitted="isSubmitted"/>
                    </template>
                  </div>
                </el-tab-pane>
                <el-tab-pane name="nfce" :disabled="hasBasicPlan">
                  <div slot="label">
                    <span class="mr-1">Configurar NFCe</span>
                    <el-tag type="info" size="mini" v-if="!hasProfessionalPlan">Indisponível</el-tag>
                    <el-tag type="success" size="mini" effect="dark"
                            v-else-if="localForm.tenant_nf.nfce.status === 'active' && localForm.tenant_nf.nfce.enabled">
                      Ativa
                    </el-tag>
                    <el-tag type="danger" size="mini" v-else>Inativa</el-tag>
                  </div>
                  <div class="pt-3 pr-3 pb-3 pl-3">
                    <template
                        v-if="(!localForm.tenant_nf.nfce.status || localForm.tenant_nf.nfce.status === 'inactive') && !hasCertificate">
                      <el-alert type="warning"
                                show-icon
                                :closable="false">
                        <div slot="title" class="fs-16">
                          Para habilitar a emissão de <strong>NFCe</strong> você precisa primeiro enviar o certificado
                          digital!
                        </div>
                      </el-alert>
                    </template>
                    <template v-if="hasCertificate">
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Habilitar NFCe (Produção)">
                            <el-switch v-model="localForm.tenant_nf.nfce.enabled"
                                       :active-value="true"
                                       :inactive-value="false"
                                       active-text="SIM"
                                       inactive-text="NÃO">
                            </el-switch>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Regime Tributário"
                                        class="is-required">
                            <el-select v-model="localForm.tenant_nf.nfce.regime_tributario"
                                       clearable
                                       filterable
                                       placeholder="Selecione um Regime Tributário"
                                       empty-text="Nenhum registro encontrado"
                                       class="el-select-full">
                              <el-option v-for="item in dependencies.nf_regime_tributario"
                                         :key="item.id"
                                         :label="`(${item.id}) ${item.name}`"
                                         :value="item.id">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Inscrição Estadual"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfce.inscricao_estadual"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="ID Token CSC"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfce.id_token_nfce"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Série Token CSC"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfce.serie_nfce"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Token CSC (NFCe Produção)"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfce.token_csc"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Próximo Número para Emissão"
                                        class="is-required">
                            <el-input v-model="localForm.tenant_nf.nfce.proximo_numero"/>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-form-item label="Configurar Ambiente de Homologação">
                        <el-switch v-model="localForm.tenant_nf.nfce_staging.enabled"
                                   :active-value="true"
                                   :inactive-value="false"
                                   active-text="SIM"
                                   inactive-text="NÃO">
                        </el-switch>
                      </el-form-item>
                      <template v-if="localForm.tenant_nf.nfce_staging.enabled">
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="ID Token CSC"
                                          class="is-required">
                              <el-input v-model="localForm.tenant_nf.nfce_staging.id_token_nfce"/>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="Série Token CSC"
                                          class="is-required">
                              <el-input v-model="localForm.tenant_nf.nfce_staging.serie_nfce"/>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <el-form-item label="Token CSC (NFCe Homologação)"
                                          class="is-required">
                              <el-input v-model="localForm.tenant_nf.nfce_staging.token_csc"/>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item label="Próximo Número para Emissão"
                                          class="is-required">
                              <el-input v-model="localForm.tenant_nf.nfce_staging.proximo_numero"/>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </template>
                    </template>
                    <form-save-button v-if="hasCertificate"
                                      :event-click="onSaveNfceConfig"
                                      text="Salvar Configurações de NFCe"
                                      submitting-text="Salvando Configurações de NFCe"
                                      :is-disabled="isSubmitted || $v.localForm.tenant_nf.nfce.$invalid"
                                      :is-submitted="isSubmitted"/>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </v-flex>
      </v-layout>
    </v-container>

    <gripo-dialog :model.sync="paymentAccount.showDialog"
                  width="70vw"
                  :persistent="false"
                  content-card-class="modal-pdf"
                  :on-close="resetPaymentAccount">
      <template v-slot:header>Dados da Conta Bancária</template>
      <template v-slot:content>
        <el-form :model="paymentAccount.data" ref="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Banco"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.bank_code.$error }">
                <el-select v-model="paymentAccount.data.bank_code"
                           clearable
                           filterable
                           placeholder="Selecione um banco"
                           empty-text="Nenhum registro encontrado"
                           class="el-select-full"
                           @input="$v.paymentAccount.data.bank_code.$touch()">
                  <el-option v-for="item in dependencies.banks"
                             :key="item.id"
                             :label="`(${item.code}) ${item.name}`"
                             :value="item.code">
                  </el-option>
                </el-select>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.bank_code.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="CPF/CNPJ"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.cpf_cnpj.$error }">
                <el-input v-model="paymentAccount.data.cpf_cnpj"
                          :disabled="paymentAccount.is_edit"
                          @input="$v.paymentAccount.data.cpf_cnpj.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.cpf_cnpj.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <el-form-item label="Agência sem o Digíto"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.agency.$error }">
                <el-input v-model="paymentAccount.data.agency"
                          @input="$v.paymentAccount.data.agency.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.agency.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="Conta"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.account.$error }">
                <el-input v-model="paymentAccount.data.account"
                          @input="$v.paymentAccount.data.account.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.account.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Dígito"
                            class="is-required"
                            :class="{ 'el-form-item--error': $v.paymentAccount.data.digit.$error }">
                <el-input v-model="paymentAccount.data.digit"
                          @input="$v.paymentAccount.data.digit.$touch()"></el-input>
                <div class="el-form-item__error" v-if="$v.paymentAccount.data.digit.$error">
                  Esse campo é obrigatório
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Conta Principal">
            <el-switch v-model="paymentAccount.data.main"
                       on-color="#13ce66"
                       on-text="SIM"
                       off-color="#ff4949"
                       off-text="NÃO">
            </el-switch>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:actions>
        <v-spacer/>
        <el-button type="success"
                   :disabled="isSubmitted || $v.paymentAccount.data.$invalid"
                   @click="savePaymentAccount">
          <i class="fas fa-check"></i>
          <span class="ml-1">Salvar Dados</span>
        </el-button>
      </template>
    </gripo-dialog>

  </el-form>
</template>
