<script>
import {mapGetters} from 'vuex'

export default {
  name: 'finish-order-generate-nfse',
  props: ['generateNfse', 'includeCpf', 'client', 'isPaid', 'disabled', 'products'],
  data() {
    return {
      generateNfseLocal: false,
      includeCpfLocal: false,
    }
  },
  watch: {
    generateNfseLocal(value) {
      this.$emit('update:generateNfse', value)
    },
    includeCpfLocal(value) {
      this.$emit('update:includeCpf', value)
    }
  },
  computed: {
    ...mapGetters(['tenant']),
    clientCpf() {
      return this.client?.cpf_cnpj || this.client?.user?.cpf || null
    },
    nfseCityConfig() {
      return (this.tenant?.settings?.tenant_nf?.nfse?.city_config ?? null)
    },
    nfseCityConfigEnderecoObrigatorioNfse() {
      return this.nfseCityConfig?.endereco_obrigatorio_nfse ?? false
    },
    nfseCityConfigCpfCnpjObrigatorioNfse() {
      return this.nfseCityConfig?.cpf_cnpj_obrigatorio_nfse ?? false
    },
    clientAddressAvailable() {
      return this.client?.address_available
    },
    generateNfseDisabled() {
      //return !this.isPaid || (this.addressRequired && !this.clientAddressAvailable) || (this.cpfCnpjRequired && !this.client.cpf_cnpj)
      return !this.isPaid || (this.cpfCnpjRequired && !this.client.cpf_cnpj)
    },
    paymentRequired() {
      return !this.isPaid
    },
    addressRequired() {
      return this.nfseCityConfigEnderecoObrigatorioNfse && !this.clientAddressAvailable
    },
    cpfCnpjRequired() {
      return this.nfseCityConfigCpfCnpjObrigatorioNfse && !this.clientCpf
    },
    hasNfseProducts() {
      return this.products.some(item => item.product.type === 'service' && item.product.nf_enabled)
    },
  }
}
</script>

<template>
  <div>
    <div class="fs-14">Gerar Nota de Serviço (NFSe)</div>
    <div>
      <el-switch v-model="generateNfseLocal"
                 :disabled="generateNfseDisabled"
                 :active-value="true"
                 active-text="SIM"
                 :inactive-value="false"
                 inactive-text="NÃO">
      </el-switch>
    </div>
    <div class="fs-14 mt-2">Identificar Cliente (CPF/CNPJ)</div>
    <div>
      <el-switch v-model="includeCpfLocal"
                 :disabled="!generateNfseLocal || !clientCpf"
                 :active-value="true"
                 active-text="SIM"
                 :inactive-value="false"
                 inactive-text="NÃO">
      </el-switch>
    </div>
    <template v-if="false">
      <div class="fs-14 mt-2">Usar Endereço do Clube</div>
      <div>
        <el-switch v-model="includeCpfLocal"
                   :disabled="!generateNfseLocal || !clientCpf"
                   :active-value="true"
                   active-text="SIM"
                   :inactive-value="false"
                   inactive-text="NÃO">
        </el-switch>
      </div>
      <div class="text-danger fs-12">
        Esse cliente não possui endereço cadastrado, você pode optar por usar o endereço no clube na emissão da nota.
      </div>
    </template>
    <el-alert type="info" class="mt-2 small-alert text-center" :closable="false"
              v-if="paymentRequired || addressRequired || cpfCnpjRequired">
      <slot name="title">
        <div v-if="paymentRequired" class="font-weight-bold">Somente comandas pagas podem gerar nota de serviço</div>
        <div v-if="false && addressRequired">O cliente precisa do endereço completo cadastrado para gerar a nota de serviço</div>
        <div v-if="cpfCnpjRequired">O cliente precisa do CPF/CNPJ cadastrado para gerar a nota de serviço</div>
        <div v-if="!hasNfseProducts">A comanda não possui serviços habilitados para emissão de nota</div>
      </slot>
    </el-alert>
  </div>
</template>
